#accueil_diapo_1 {
	position: relative;
	height: 75vh;
	background: url(../images/image_illustration_1.jpg) no-repeat center center;
	background-size: cover;
}

.scroll_message_accueil {
	color: white;
	font-size: 1.5em;
	font-weight: 600;
	width: 60px;
	height: 20px;
	margin-top: 40px;
	margin-left: 50%;
	transform: rotate(-90deg);
	background: url(../images/scroll.png) no-repeat center center;
	background-size: contain;
}

.bloc_txt_image {
	position: relative;
	margin-top: 10vh;
	margin-left: 10vw;
	color: white;
	font-size: 1.2em;
	border-left: 2px solid white;
}


// ------------- DIAPO 2 -------------
#accueil_diapo_2 {
	position: relative;
}

#diapo2_gauche {
	padding: 50px;
	padding-left: 100px;
}

@media (max-width: $screen-md) { 
	#diapo2_gauche { padding-left: 50px; }
}

#diapo2_droite {
	padding: 50px;
	background-color: $color2;
	color: white;
	font-size: 1.1em;
}


// ------------- DIAPO 3 -------------
#accueil_diapo_3 {
	position: relative;
	display: flex;
}

#diapo3_gauche {
	overflow: hidden;
	padding: 50px;
	padding-left: 100px;
	color: white;
	background-color: $color2;
	width: 100%;
}

#diapo3_gauche.in-view {
	padding: 50px;
	padding-left: 100px;
	color: white;
	background-color: $color2;
	width: 100%;	
}

@media (max-width: $screen-md) { 
	#diapo3_gauche { padding-left: 50px; }
	#diapo3_gauche.in-view { padding-left: 50px; }
}

@media (min-width: $screen-md) { 

	#diapo3_gauche.in-view {
		animation-name: anim_diapo3_gauche;
		animation-delay: 0s;
		animation-duration: 0.5s;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
	}
}

#diapo3_photo_responsive {
	width: 100vw;
	height: 300px;
	background: url(../images/image_illustration_2.jpg) no-repeat center center;
	background-size: cover;
	margin-top: 20px;
}

#diapo3_droite {
	height: auto;
	background: url(../images/image_illustration_2.jpg) no-repeat center center;
	background-size: cover;
	width: 0%;
}

#diapo3_droite.in-view {
	height: auto;
	background: url(../images/image_illustration_2.jpg) no-repeat center center;
	background-size: cover;

	animation-name: anim_diapo3_droite;
	animation-delay: 0s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes anim_diapo3_gauche {
    from { width: 100%; }
    to { width: 58.33333%; }
}

@keyframes anim_diapo3_droite {
    from { width: 0%; }
    to { width: 41.66667%; }
}


// Anim titre
#diapo3_anim_titre .anim_titre_motcle.in-view {
	animation-name: diapo3_animtitre_motcle;
	animation-delay: 0.75s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#diapo3_anim_titre .mot1 {
	color: $color3;
	left: 200%;
	top: 10%;
}

#diapo3_anim_titre .mot1.in-view {
	animation-name: diapo3_animtitre_mot1;
	animation-delay: 0s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#diapo3_anim_titre .mot2 {
	color: $color3;
	left: 200%;
	top: 30%;
}

#diapo3_anim_titre .mot2.in-view {
	animation-name: diapo3_animtitre_mot2;
	animation-delay: 0.2s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#diapo3_anim_titre .mot3 {
	color: $color3;
	left: 200%;
	top: 50%;
}

#diapo3_anim_titre .mot3.in-view {
	animation-name: diapo3_animtitre_mot3;
	animation-delay: 0.4s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes diapo3_animtitre_motcle {
    0% { opacity: 0; left: 40%; }
    100% { opacity: 1; left: 50%; }
}

@keyframes diapo3_animtitre_mot1 {
    0% { opacity: 1; left: 200%; }
    60% { opacity: 1; }
    100% { opacity: 0.4; left: 43%; }
}

@keyframes diapo3_animtitre_mot2 {
    0% { opacity: 1; left: 200%; }
    60% { opacity: 1; }
    100% { opacity: 0.4; left: 50%; }
}

@keyframes diapo3_animtitre_mot3 {
    0% { opacity: 1; left: 200%; }
    60% { opacity: 1; }
    100% { opacity: 0.4; left: 80%; }
}


// ------------- DIAPO 4 -------------
#accueil_diapo_4 {
	position: relative;
	min-height: 200px;
	color: $color3;
}

// Anim titre
#diapo4_anim_titre .anim_titre_motcle.in-view {
	animation-name: diapo4_animtitre_motcle;
	animation-delay: 0.75s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#diapo4_anim_titre .mot1 {
	color: $color3;
	left: -200%;
	top: 10px;
}

#diapo4_anim_titre .mot1.in-view {
	animation-name: diapo4_animtitre_mot1;
	animation-delay: 0s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#diapo4_anim_titre .mot2 {
	color: $color3;
	left: -200%;
	top: 30%;
}

#diapo4_anim_titre .mot2.in-view {
	animation-name: diapo4_animtitre_mot2;
	animation-delay: 0.2s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#diapo4_anim_titre .mot3 {
	color: $color3;
	left: -200%;
	top: 50%;
}

#diapo4_anim_titre .mot3.in-view {
	animation-name: diapo4_animtitre_mot3;
	animation-delay: 0.4s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes diapo4_animtitre_motcle {
    0% { opacity: 0; left: 40%; }
    100% { opacity: 1; left: 50%; }
}

@keyframes diapo4_animtitre_mot1 {
    0% { opacity: 1; left: -200%; }
    60% { opacity: 1; }
    100% { opacity: 0.4; left: 45%; }
}

@keyframes diapo4_animtitre_mot2 {
    0% { opacity: 1; left: -200%; }
    60% { opacity: 1; }
    100% { opacity: 0.4; left: 50%; }
}

@keyframes diapo4_animtitre_mot3 {
    0% { opacity: 1; left: -200%; }
    60% { opacity: 1; }
    100% { opacity: 0.4; left: 70%; }
}



// ------------- DESSIN 1 -------------
#accueil_dessingauche_container {
	position: absolute;
	height: auto;
	width: 60vw;
	transform: rotate(-50deg) translate(-50%, -50%);
	transform-origin: 0% 0%;
}

#accueil_dessingauche_container.in-view {
	animation-name: accueil_dessingauche_container;
	animation-delay: $dessin1_delai_animation;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.5,0,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#svg_dessin1 {
	width: 100%;
}

.path_dessin1_triangle {
	fill: white;
}

.path_dessin1_rond {
	fill: $color2;
}

#dessin1_img {
	
}

@keyframes accueil_dessingauche_container {
    0% { transform: rotate(-50deg) translate(-50%, -50%); }
    100% { transform: rotate(0deg) translate(-50%, -50%); }
}


// ------------- DIAPO 5 -------------
#accueil_diapo_5 {
	position: relative;
	min-height: 200px;
	color: $color3;
}

// Anim titre
#diapo5_anim_titre .anim_titre_motcle.in-view {
	animation-name: diapo5_animtitre_motcle;
	animation-delay: 0.75s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#diapo5_anim_titre .mot1 {
	color: $color3;
	left: 200%;
	top: 10px;
}

#diapo5_anim_titre .mot1.in-view {
	animation-name: diapo5_animtitre_mot1;
	animation-delay: 0s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#diapo5_anim_titre .mot2 {
	color: $color3;
	left: 200%;
	top: 30%;
}

#diapo5_anim_titre .mot2.in-view {
	animation-name: diapo5_animtitre_mot2;
	animation-delay: 0.2s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#diapo5_anim_titre .mot3 {
	color: $color3;
	left: 200%;
	top: 50%;
}

#diapo5_anim_titre .mot3.in-view {
	animation-name: diapo5_animtitre_mot3;
	animation-delay: 0.4s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes diapo5_animtitre_motcle {
    0% { opacity: 0; left: 40%; }
    100% { opacity: 1; left: 50%; }
}

@keyframes diapo5_animtitre_mot1 {
    0% { opacity: 1; left: 200%; }
    60% { opacity: 1; }
    100% { opacity: 0.4; left: 65%; }
}

@keyframes diapo5_animtitre_mot2 {
    0% { opacity: 1; left: 200%; }
    60% { opacity: 1; }
    100% { opacity: 0.4; left: 75%; }
}

@keyframes diapo5_animtitre_mot3 {
    0% { opacity: 1; left: 200%; }
    60% { opacity: 1; }
    100% { opacity: 0.4; left: 70%; }
}


// ------------- DIAPO 6 -------------
#accueil_diapo_6 {
	position: relative;
	height: 300px;
	color: $color3;
	text-align: right;
	padding-top: 2vw;
	padding-right: 20vw;
	background: url(../images/image_illustration_3.jpg) no-repeat center center;
	background-size: cover;
}



// ------------- DIAPO 7 -------------
#accueil_diapo_7 {
	position: relative;
	color: $color3;
}

@media (min-width: $screen-md) { 

	#diapo3_gauche.in-view {
		animation-name: anim_diapo3_gauche;
		animation-delay: 0s;
		animation-duration: 0.5s;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
	}
}

@media (max-width: $screen-md) { 

	#liste_communication {
		padding-left: 0;
	}
}


// Anim titre
#diapo7_anim_titre .anim_titre_motcle {
	top:80%;
}

#diapo7_anim_titre .anim_titre_motcle.in-view {
	animation-name: diapo7_animtitre_motcle;
	animation-delay: 0.75s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#diapo7_anim_titre .mot1 {
	color: $color3;
	left: 200%;
	top: 10%;
}

#diapo7_anim_titre .mot1.in-view {
	animation-name: diapo7_animtitre_mot1;
	animation-delay: 0s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#diapo7_anim_titre .mot2 {
	color: $color3;
	left: 200%;
	top: 30%;
}

#diapo7_anim_titre .mot2.in-view {
	animation-name: diapo7_animtitre_mot2;
	animation-delay: 0.2s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#diapo7_anim_titre .mot3 {
	color: $color3;
	left: 200%;
	top: 50%;
}

#diapo7_anim_titre .mot3.in-view {
	animation-name: diapo7_animtitre_mot3;
	animation-delay: 0.4s;
    animation-duration: $anim_titre_duree_anim;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes diapo7_animtitre_motcle {
    0% { opacity: 0; left: 60%; }
    100% { opacity: 1; left: 70%; }
}

@keyframes diapo7_animtitre_mot1 {
    0% { opacity: 1; left: 200%; }
    60% { opacity: 1; }
    100% { opacity: 0.4; left: 50%; }
}

@keyframes diapo7_animtitre_mot2 {
    0% { opacity: 1; left: 200%; }
    60% { opacity: 1; }
    100% { opacity: 0.4; left: 50%; }
}

@keyframes diapo7_animtitre_mot3 {
    0% { opacity: 1; left: 200%; }
    60% { opacity: 1; }
    100% { opacity: 0.4; left: 35%; }
}

// Dessin gauche
#accueil_dessinbas_container {
	padding-right: 40px;
	transform: translateY(5%);
}

#svg_accueil_basgauche {
	position: absolute;
	bottom: 0;
	max-width: 600px;
}

#remplacement_svg_accueil_basgauche {
	position: absolute;
	bottom: 0;
	max-width: 600px;
	height: 100%;
	width: 100%;
	background: url(../images/remplacement_dessin_accueil_bas.png) no-repeat center center;
	background-size: contain;
	opacity: 0;
}

#remplacement_svg_accueil_basgauche { display: none; }

#remplacement_svg_accueil_basgauche.in-view {
	animation-name: apparition;
	animation-delay: $dessin2_delai_animation;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.dessin2_path_triangle {
	fill: transparent;
	stroke: #fff;
	stroke-miterlimit:10;
	stroke-width:2.41px;
	stroke-dasharray: 1000px;
  	stroke-dashoffset: 1000px;
  	stroke-linecap: round;
}

#svg_accueil_basgauche.in-view .dessin2_path_triangle {
	animation-name: dessin2_path_triangle;
	animation-delay: $dessin2_delai_animation;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes dessin2_path_triangle {
    0% { stroke-dashoffset: 1000px; }
    50% { stroke-dashoffset: 0px; fill: transparent }
    100% { stroke-dashoffset: 0px; fill: #fff }
}

.dessin2_path_zones_vides {
	fill: none;
	stroke: #fff;
	stroke-miterlimit:10;
	stroke-width:2.41px;
	stroke-dasharray: 1000px;
  	stroke-dashoffset: 1000px;
  	stroke-linecap: round;
}

#svg_accueil_basgauche.in-view .dessin2_path_zones_vides {
	animation-name: dessin2_path_zones_vides;
	animation-delay: $dessin2_delai_animation;
    animation-duration: 1s;
    animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes dessin2_path_zones_vides {
    0% { stroke-dashoffset: 1000px; }
    100% { stroke-dashoffset: 0px; }
}

.dessin2_path_zone_violette {
	fill: transparent;
	stroke: $color2;
	stroke-miterlimit:10;
	stroke-width:2.41px;
	stroke-dasharray: 1000px;
  	stroke-dashoffset: 1000px;
  	stroke-linecap: round;
}

#svg_accueil_basgauche.in-view .dessin2_path_zone_violette {
	animation-name: dessin2_path_zone_violette;
	animation-delay: $dessin2_delai_animation;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0,.5,.5,1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes dessin2_path_zone_violette {
    0% { stroke-dashoffset: 1000px; }
    50% { stroke-dashoffset: 0px; fill: transparent }
    100% { stroke-dashoffset: 0px; fill: $color2 }
}