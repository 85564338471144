html { 
	height:100%;
	overflow-x: hidden; 
}

body {
	height: 100%;
	background-color: $color1;
	margin: 0; padding: 0;
}

nav {
	z-index: 20002 !important;
}

p, span, h1, h2, h3, h4, h5 { font-family: "interface", sans-serif !important; }

// ---------------------- NAVBAR ----------------------
.navbar {
	background-color: white !important;
	border: none;
} 

.navbar-brand {
	width: $navbar-height * 4.3; // 4.3 = ratio de l'image
	margin-left: $epaisseur_cadre;
	background: url(../images/logo_navbar.png) no-repeat center center;
	background-size: contain;
}

.navbar-brand:hover {
	color: darken($titre_nav_color, 10%) !important;
}

.mot_menu {
	position: absolute;
	font-size: 0.6em;
	top: 0;
	left: 50%;
	transform: translate(-50%, -2px);
}

.navbar-header {
	float: none;
}
.navbar-toggle {
	display: block;
	border: none;
	margin-top: 7px;
	margin-right: $epaisseur_cadre;
}
.navbar-toggle:hover {
	background-color: transparent !important;
}
.navbar-toggle .icon-bar {
	background-color: black !important;
}
.navbar-collapse.collapse {
	display: none!important;
}
.navbar-nav {
	float: none!important;
}
.navbar-nav>li {
	float: none;
}
.navbar-nav>li>a {
	font-size: calc(1em + 1vw);
	line-height: calc(1em + 1vw);
	font-weight: 600;
	color: $color_nav_links !important;
	padding: 15px;
	padding-left: 10px;
}
.navbar-nav>li>a:hover, .navbar-nav>li>a:active {
	background-color: transparent !important;
	color: white !important;
}
.navbar-collapse.collapse.in{
	display:block !important;
}
.navbar .navbar-nav > li > a:hover, .navbar .navbar-nav > li > a:focus, .navbar .navbar-nav > li > a:active, .navbar .navbar-nav > li > a:visited {
    background-color: transparent !important;
}
.navbar .navbar-toggle:hover, .navbar .navbar-toggle:focus {
	background-color: transparent !important;
}

#navbar ul .active a {
	color: white !important;
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
	max-height: 100vh;
}

#navbar {
	height: 100vh;
	overflow: hidden;
	background-color: $color2;
}

.nav {
	float: right !important;
	margin-top: 20vh;
}

.nav_contact_bloc {
	margin-top: $nav_contact_bloc_margintop;
	padding-left: 10px;
	color: white;
	float: right !important;
}

.nav_contact_bloc h4 {
	margin: 5px;
}

.barre_verticale_nav {
	height: $nav_barre_verticale_height;
	margin-top: $nav_barre_verticale_margintop;
	border-right: 4px solid $color3;
}


// Animation du toggler
.navbar-toggle {
	.icon-bar {
		transition: all 0.2s;
	}
	.top-bar {
		transform: rotate(45deg);
		transform-origin: 10% 10%;
	}
	.middle-bar {
		opacity: 0;
	}
	.bottom-bar {
		transform: rotate(-45deg);
		transform-origin: 10% 90%;
	}
}

.navbar-toggle.collapsed {
	.top-bar {
		transform: rotate(0);
	}
	.middle-bar {
		opacity: 1;
	}
	.bottom-bar {
		transform: rotate(0);
	}
}
// ---------------------- FIN NAVBAR ----------------------


// ------------------------ GENERAL ------------------------

.tk-expo-serif-pro {
	font-style: italic;
}

.nowrap { white-space: nowrap; }

// En-tête d'une page classique
#phrase_haut_page {
	position: absolute;
	color: $color3;
	top: $phrase_haut_page_margintop;
	left: 20vw;
	font-size: calc(1.2em + 0.5vw);
	opacity: 0;

	animation-name: anim_pĥrase_haut_̂page;
	animation-delay: 0s;
    animation-duration: $anim_main_logo_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
} 

@keyframes anim_pĥrase_haut_̂page {
    from { opacity: 0; top: $phrase_haut_page_margintop + 30px; }
    to { opacity: 1; top: $phrase_haut_page_margintop; }
}

#main_logo {
	position: absolute;
	z-index: 1;
	top: $main_logo_top;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;

	animation-name: anim_main_logo;
	animation-delay: 0s;
    animation-duration: $anim_main_logo_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes anim_main_logo {
    from { opacity: 0; top: $main_logo_top + 10%; }
    to { opacity: 1; top: $main_logo_top; }
}

#svg_main_logo {
	display: inline-block;
	height: $main_logo_size;
	width: $main_logo_size;
}

.path_main_logo_rond { fill: $color3; }
.path_main_logo_triangle { fill: $color2; }

#main_logo_texte {
	display: inline-block;
	margin-left: $main_logo_ecart_logotxt;
	height: $main_logo_size;
	width: $main_logo_size * 5;
	background: url(../images/texte_logo.png) no-repeat;
	background-size: contain;
}

.grosmot_entetes {
	position: absolute;
	z-index: 0;
	width: 100vw;
	font-size: calc(4em + 3vw);
	font-weight: 600;
	line-height: 0.8em;
	opacity: 0;
	white-space: pre-line;

	animation-name: anim_grosmot_entetes;
	animation-delay: 0s;
    animation-duration: $anim_grosmot_entetes_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes anim_grosmot_entetes {
    from { opacity: 0; left: 0%; }
    to { opacity: 1; left: 20%; }
}

.grostitre {
	font-size: calc(3.5em + 3vw);
	font-weight: 600;
	line-height: 0.8em;
	margin-top: 20px;
}

.scroll_message {
	position: absolute;
	width: 60px;
	height: 20px;
	left: 50%;
	bottom: 35px;
	transform-origin: 0% 50%;
	transform: rotate(-90deg) translate(-50%, -100%);
	background: url(../images/scroll_black.png) no-repeat center center;
	background-size: contain;
}


// Trucs utiles en vrac
#cadre_permanent {
	position: fixed;
	z-index: 20000;
	top: 0;
	height: 110vh;
	width: 100vw;
	border: $epaisseur_cadre solid white;
	border-right: calc(#{$epaisseur_cadre} + 18px) solid white;
	border-bottom: calc(#{$epaisseur_cadre} + 18px + 8vh) solid white;
	pointer-events: none;

	-webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

/* @media (max-width: $screen-md) { 
	#cadre_permanent { 
		height: 150vh;
	}
} */

.spacer_cadre_haut {
	width: 100%;
	height: $epaisseur_cadre;
	min-height: $navbar-height;
}

.padding_cadre {
	padding: 0 $epaisseur_cadre 0 $epaisseur_cadre;
}

.diapo_fullheight {
	height: 100vh;
}

.colorwhite { color: white; }
.colorgrey { color: $color3; }

.filler {
	width: 100%;
	height: 1000px;
}

.barre_verticale_container { padding-right: 10px; }
.barre_verticale {
	height: 150px;
	border-right: 4px solid $color3;
}

.puce_bas_texte {
	height: 4px;
	width: 40px;
	background-color: $color3;
}

.liste_italic {
	 list-style-type: none;
	 font-style: italic;
}

.flex { display: flex; }

@media (max-width: $screen-md) { 
	.flex { display: block; }
}

@media (min-width: $screen-md) { 

	.table-row {
		display: table;
		table-layout: fixed;
	}

	.table-row [class*="col-"] {
		display: table-cell;
		float: none;
	}
}

.inlineblock {
    display: inline-block;
}


// Liste déroulante
.svg_puce_liste {
	height: $puce_liste_size;
	width: $puce_liste_size;
}

.titre_liste_deroulante {
	cursor: pointer;
}

.titre_liste_deroulante .svg_puce_liste {
	margin-left: 10px;
	transform: rotate(0deg);
	transition: transform 0.3s;
}

.titre_liste_deroulante .svg_puce_liste .path_puce_liste {
	fill: $color2;
	transition: fill 0.3s;
}

.titre_liste_deroulante.collapsed .svg_puce_liste {
	transform: rotate(-90deg);
}

.titre_liste_deroulante.collapsed .svg_puce_liste .path_puce_liste {
	fill: $color3;
}

// ---------------------- FIN GENERAL ----------------------



// ---------------------- ANIMATION TITRE ----------------------

.zone_animation_titre {
	position: relative;
	width: 100%;
	height: 300px;
	margin: 30px 0 30px 0;
	/* background-color: blue; */
}

.anim_titre_motcle {
	position: absolute;
	z-index: 21;
	font-size: 1.2em;
	font-weight: 600;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
}

.anim_titre_mot_lg {
	position: absolute;
	display: block;
	z-index: 20;
	font-size: calc(4.5em + 3.2vw);
	font-weight: 600;
	transform: translateX(-50%);
	mix-blend-mode: multiply;
}

.anim_titre_mot_md {
	position: absolute;
	z-index: 20;
	font-size: calc(3em + 3vw);
	font-weight: 600;
	transform: translateX(-50%);
	mix-blend-mode: multiply;
}

.anim_titre_mot_sm {
	position: absolute;
	z-index: 20;
	font-size: calc(2em + 2.5vw);
	font-weight: 600;
	transform: translateX(-50%);
	mix-blend-mode: multiply;
}


// ---------------------- FOOTER ----------------------
#footer {
	position: relative;
	color: white;
	background-color: $color3;
	min-height: 200px;
	padding: 4vw;
	padding-bottom: 10vw;
}

@media (max-width: $screen-sm) { 
	#footer { 
		padding-bottom: 20vw;
		padding-top: 8vw;
	} 
}

.calage_footer {
	float: right;
	margin-right: 2vw;
}

.svg_footer_container {
	height: $footer_logo_height;
}

#svg_footer {
	height: 100%;
	width: auto;
}

#footer_contacteznous {
	float: right;
	font-size: 2em;
	line-height: $footer_logo_height;
	width: 250px;
}

#footer .col-md-6 .col-md-2 {
	width: 110px;
}

/* @media (max-width: $screen-lg) { #footer_contacteznous { padding-left: 20px !important; } }
@media (max-width: $screen-sm) { #footer_contacteznous { padding-left: 35px !important; } } */

#footer_bloctxt {
	float: left;
	line-height: 0.8em;
	margin-top: 40px;
	width: 300px;
}

#footer_bloctxt .ligne1 {
	font-weight: 700;
}

#footer_bloctxt .ligne2 {
	font-family: "expo-serif-pro",serif;
	font-size: 1.2em;
}

#footer_bloctxt .ligne3 {
	color: white !important;
	font-size: 1.8em;
	font-weight: 900;
	margin: 12px 0 12px 0;
}

#footer_bloctxt .ligne4 {
	color: #bbbbbb;
	font-size: 1.2em;
	font-weight: 700;
	text-decoration: underline;
}

#footer_bloctxt .ligne4 a {
	color: #bbbbbb;
	text-decoration: underline;
}

#footer_ligne_bas {
	position: absolute;
	bottom: $epaisseur_cadre;
	left: $epaisseur_cadre;
	padding-bottom: 10px;
}

#legals {
	cursor: pointer;
	text-decoration: underline;
}

#legal-mentions {
	display: none;
}

.popover {
	max-width: 80vw;
	left: 40px !important;
}

.popover-content {
	background-color: $color2;
}

.popover-content a {
	color: #bbbbbb;
}

.popover.top > .arrow {
    left: 20px !important;
}

.lien_rectangle, .lien_rectangle:hover {
	color: white;
	text-decoration: underline;
}

.texte_links_container {
	position: relative;
	width: 380px;
}

.zone_logo {
	float: left;
	width: 81px;
}

.links_container {
	position: absolute;
	bottom: 0;
	right: 0;
}

.link_picto_bas, .link_picto_bas:hover, .link_picto_bas:active, .link_picto_bas:visited {
	position: relative;
	margin: 2px;
	top: 0;
	color: white;
	line-height: 1px;
}

.champs_newsletter {
	width: 300px;
	background-color: lighten($color3, 25%);
	color: $color3;
	border: none;
	line-height: 2em;
	padding-left: 5px;
}

.inscription_newsletter_container {
	position: relative;
	width: 380px;
	margin-top: 40px;
}

.bouton_newsletter {
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: $color2;
	color: white;
	border: none;
	line-height: 2em;
}

