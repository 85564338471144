#navbar.color5 { background-color: $color5; }

#news_diapo_1 {
	position: relative;
	height: 30vh;

	#main_logo {
		top: 60% !important;
	}
}

#news_diapo_1 .path_main_logo_triangle { fill: $color5; }

#news_diapo_1 #main_logo #main_logo_texte {
	background: url(../images/texte_logo.png) no-repeat;
	background-size: contain;
}

.navbar-brand.color5 {
	background: url(../images/logo_navbar.png) no-repeat center center;
	background-size: contain;
}

#grosmot_news {
	top: 35%;
	left: 10%;
	animation-name: grosmot_news;
	animation-delay: 0s;
    animation-duration: $anim_grosmot_entetes_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes grosmot_news {
    from { opacity: 0; left: 10%; }
    to { opacity: 1; left: 40%; }
}

#news_diapo_1 .soustitre_milieu {
	position: relative;
	width: 100%;
	top: 55%;
	color: $color3;
	text-align: center;

	animation-name: apparition;
	animation-delay: 0s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#news_diapo_1 .scroll_message {
	bottom: 130px;
}


// AFFICHAGE DEs NEWS EN ELLES-MÊMES

.news_container {
	position: relative;
	margin-top: 30px;
	margin-bottom: 40px;
	font-family: "interface",sans-serif !important;
}

.news_anchor {
	position: absolute; 
	top: -$news_decalage_anchor;
}

.news_date {
	margin-top: 30px;
	font-weight: 600;
}

.trait_date {
	background-color: $color2;
	width: 70px;
	height: 2px;
	vertical-align: middle;
	display: inline-block;
}

.news_titre {
	margin-bottom: $news_margin_titre_img;
}

.news_texte {
	font-size: $news_texte_fontsize;
}

.news_container p {
	font-size: $news_texte_fontsize;
	margin-bottom: 20px !important;
}

.news_container h1, .news_container h2, .news_container h3, .news_container h4, .news_container h5, .news_container h6 {
	margin-top: 34px !important;
	margin-bottom: 20px !important;
}

.news_img_container {
	overflow: hidden;
	width: 100%;
	/* height: $news_img_height; */
	margin-top: $news_margin_bas_texte;
}

.news_img {
	/* height: 100%; */
	width: 100%;
	object-fit: cover;
	margin-bottom: 30px;
}

.news_share_container {
	margin-top: $news_margin_avant_share;
	text-align: right;
}

.twitter-share-button {
	vertical-align: bottom;
}