#navbar.color4 { background-color: $color4; }

#entreprise_diapo_1 {
	position: relative;
	/* height: 112vh; */
}

@media (max-width: $screen-xs+100px) { 
	/* #entreprise_diapo_1 {
		position: relative;
		height: 100vh;
	}
 */
	#entreprise_diapo_1 #main_logo {
		transform: translate(-50%, -220%);
	}
}

/* @media (max-width: 350px) { 
	#entreprise_diapo_1 {
		height: 115vh;
	}
}

@media (max-width: 290px) { 
	#entreprise_diapo_1 {
		height: 125vh;
	}
} */

#entreprise_diapo_1 .path_main_logo_triangle { fill: $color4; }

#entreprise_diapo_1 #main_logo #main_logo_texte {
	background: url(../images/texte_logo.png) no-repeat;
	background-size: contain;
}

.navbar-brand.color4 {
	background: url(../images/logo_navbar.png) no-repeat center center;
	background-size: contain;
}

#grosmot_entreprise {
	top: 30%;
	left: -10%;
	transform: translateY(-50%);
	animation-name: grosmot_entreprise;
	animation-delay: 0s;
    animation-duration: $anim_grosmot_entetes_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes grosmot_entreprise {
    from { opacity: 0; left: -10%; }
    to { opacity: 1; left: 10%; }
}

#texte_debut_entreprise {
	/* position: absolute; */
	bottom: 20px;
	padding: $hauteur_au_dessus_texte 100px 20px 20px !important;
}

@media (max-width: $screen-md) { 

	#texte_debut_entreprise {
		padding: $hauteur_au_dessus_texte*2 50px 30px 30px !important;
	}
}

@media (max-width: 400px) { 

	#texte_debut_entreprise {
		padding: $hauteur_au_dessus_texte*3 50px 30px 30px !important;
	}
}

#entreprise_diapo_1 .scroll_message {
	bottom: calc(26vh + 3vw);
}

#diapo2_entreprise_photo {
	height: 30vw;
	background: url(../images/photo_eveline_2024.jpg) no-repeat center center;
	background-size: cover;
}

#diapo2_entreprise_droite {
	padding: 50px;
	background-color: $color4;
	color: white;
	font-size: 1.1em;
}

.nom_client {
	color: $color3;
	font-size: 2em;
	mix-blend-mode: multiply;
}

.troisptipoints {
	background-color: $color1;
	color: $color3;
	font-size: 1.5em;
	font-weight: 600;
	padding: 30px;
}

#entreprise_diapo_3 {

}

#entreprise_diapo_3 #background_haut {
	position: relative;
	width: 100%;
	padding-bottom: 25vw;
	background-color: $color5b;
}

@media (max-width: $screen-md) { 
	#entreprise_diapo_3 #background_haut {
		padding-bottom: 35vw;
	}
}

#entreprise_diapo_3 #background_bas {
	position: relative;
	width: 100%;
	background-color: $color1;
}

#entreprise_diapo_3 #background_bas .col_gauche {

}

#entreprise_diapo_3 #background_bas .col_droite {
	z-index: 1;
	/* background: url(../images/illustration_batiment.jpg) no-repeat center center;
	background-size: cover; */
}


#cible_textes_haut {
	position: relative;
	z-index: 10;
	top: 20px;
	opacity: 0;
}

#cible_textes_haut.in-view {
	animation-name: apparition_cible_textes_haut;
	animation-delay: 0.3s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes apparition_cible_textes_haut {
	from { opacity: 0; transform: translate(0, 20%); }
    to { opacity: 1; transform: translate(0, 0%); }
}

#cible_textes_haut table {
	margin: 0 2vw 0 2vw;
}

#cible_textes_haut table tr .zone_texte {
	width: 33%;
	padding: 2vw;
	vertical-align: top;
}

@media (max-width: $screen-sm) { 
	#cible_textes_haut table tr .zone_texte {
		width: auto;
	}
}

#cible_textes_haut .chiffre .rond {
	height: 35px;
	width: 35px;
	padding: 0px;
	border-radius: 200px;
	font-size: 1.7em;
	font-weight: 900;
	text-align: center;
	color: white;
	background-color: black;
}

#cible_textes_haut .zone_texte {
	padding: 20px;
	color: white;
}

#cible_textes_haut .zone_texte .titre {
	font-size: calc(1.7em + 0.3vw);
	font-weight: 900;
}

#cible_textes_haut .zone_texte .texte {
	font-size: calc(1em + 0.2vw);
	font-weight: 500;
}

#cible_textes_haut .zone_texte span {
	font-size: calc(1.1em + 0.2vw);
	font-weight: 700;
	font-style: italic;
}

#cible_texte_bas {
	float: left;
	margin-top: 20vw;
	margin-left: 20%;
	margin-bottom: 20vw;
	padding: 2vw;
}

@media (max-width: $screen-md) { 
	#cible_texte_bas {
		margin-top: 40vw;
		margin-left: 0%;
	}
}

#cible_texte_bas .titre {
	font-size: 1.5em;
	font-weight: 700;
	font-style: italic;
}



// ANIMATION CIBLE
#animation_cible_container {
	position: absolute;
	z-index: 5;
	top: 0;
	width: 100%;
	height: $animation_cible_container_height;
	transform: translateY(-50%);
}

#svg_animation_cible {
	height: 100%;
	width: 100%;
}

#animcible_rond1 { fill: $color3; }
#animcible_rond2 { fill: $color5b; }
#animcible_rond3 { fill: white; }
#animcible_rond1_masked { fill: white; }
#animcible_rond2_masked { fill: $color3; }
#animcible_rond3_masked { fill: $color5b; }

#animcible_triangle_mask1 { fill: #ffffff; }
#animcible_triangle_mask2 { fill: #ffffff; }
#animcible_triangle_mask3 { fill: #ffffff; }

#animcible_triangle { fill: $color5b; }

#txt1 { 
	position: absolute;
	top: 50%;
	left: 10vw;
	transform: translate(-50%, -50%);
	height: calc(1em + 0.7vw);
	width: calc(1em + 0.7vw);
	padding: 0px;
	border-radius: 200px;
	font-size: calc(1em + 0.7vw);
	font-weight: 900;
	text-align: center;
	color: white;
	background-color: black;
	opacity: 0;
}

#txt1.in-view { 
	animation-name: apparition;
	animation-delay: 1s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#txt2 { 
	position: absolute;
	top: 50%;
	left: 19vw;
	transform: translate(-50%, -50%);
	height: calc(1em + 0.7vw);
	width: calc(1em + 0.7vw);
	padding: 0px;
	border-radius: 200px;
	font-size: calc(1em + 0.7vw);
	font-weight: 900;
	text-align: center;
	color: white;
	background-color: black;
	opacity: 0;
}

#txt2.in-view { 
	animation-name: apparition;
	animation-delay: 1.1s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#txt3 { 
	position: absolute;
	top: 50%;
	left: 25vw;
	transform: translate(-50%, -50%);
	height: calc(1em + 0.7vw);
	width: calc(1em + 0.7vw);
	padding: 0px;
	border-radius: 200px;
	font-size: calc(1em + 0.7vw);
	font-weight: 900;
	text-align: center;
	color: white;
	background-color: black;
	opacity: 0;
}

#txt3.in-view { 
	animation-name: apparition;
	animation-delay: 1.2s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes apparition {
	from { opacity: 0; }
    to { opacity: 1; }
}

#groupe_fleches {
	position: absolute;
	height: $groupe_fleches_height;
	width: $groupe_fleches_height * 3; // Ratio de l'image
	top: 50%;
	transform: translate(-15vw, -50%);
	background: url(../images/groupe_fleches.png) no-repeat center center;
	background-size: contain;
	opacity: 0;
}

#groupe_fleches.in-view {
	animation-name: apparition_fleches;
	animation-delay: 1.5s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes apparition_fleches {
	from { opacity: 0; transform: translate(-15vw, -50%); }
    to { opacity: 1; transform: translate(-11vw, -50%); }
}

#groupe_points {
	position: absolute;
	height: $groupe_points_height;
	width: $groupe_points_height * 3; // Ratio de l'image
	top: 50%;
	transform: translate(15vw, -50%);
	background: url(../images/groupe_points.png) no-repeat center center;
	background-size: contain;
	opacity: 0;
}

#groupe_points.in-view {
	animation-name: apparition;
	animation-delay: 2s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#groupe_etiquette_vous {
	position: absolute;
	top: 35vw;
	left: 10vw;
	transform: translate(-50%, 0);
	width: auto;
	text-align: center;
	opacity: 0;
}

@media (max-width: $screen-md) { 

	#groupe_etiquette_vous {
		top: 35vw;
	}
}

#groupe_etiquette_vous.in-view {
	animation-name: apparition;
	animation-delay: 2.5s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#groupe_etiquette_vos_clients {
	position: absolute;
	top: 35vw;
	left: 56.5vw;
	transform: translate(-50%, 0);
	width: auto;
	text-align: center;
	opacity: 0;
}

@media (max-width: $screen-md) { 

	#groupe_etiquette_vos_clients {
		top: 35vw;
	}
}

#groupe_etiquette_vos_clients.in-view {
	animation-name: apparition;
	animation-delay: 3s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.etiquette_titre {
	color: white;
	background-color: $color3;
	width: auto;
	padding: 5px 15px;
	font-size: calc(0.6em + 0.8vw);
	font-weight: 600;
	text-align: center;
}

.etiquette_fleche {
	height: $fleches_etiquettes_size;
}

.image_fleche {
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	height: $fleches_etiquettes_size;
	width: $fleches_etiquettes_size;
	fill: white;
	background: url(../images/fleche_bas_blanche.png) no-repeat center center;
	background-size: contain;
}

#entreprise_diapo_3 #mot_bas {
	position: absolute;
	z-index: 10;
	bottom: 0;
	left: 0;
	/* color: white;
	font-size: calc(3em + 2vw);
	font-weight: 700; */
}


// DIAPO 4
#entreprise_diapo_4 {
	position: relative;
	width: 100%;
	z-index: 10;
	padding-top: 10vw;
	color: $color3;
}

#entreprise_diapo_4 #mot_haut {
	position: absolute;
	z-index: 10;
	top: 0;
	left: $epaisseur_cadre;
	margin: 0;
}

#entreprise_diapo_4 .col-md-12 {
	margin-bottom: 50px;
}

#entreprise_diapo_4 .col-md-12 .col-md-5 .titre {
	font-size: 1.2em;
	font-style: italic;
}

#entreprise_diapo_4 .col-md-12 .col-md-5 .titre span {
	font-size: 1.3em;
	font-style: italic;
}

#entreprise_diapo_4 .col_gauche {
	padding: 5vw;
	background-color: $color4;
	color: white;
	font-size: 1.1em;
}

#entreprise_diapo_4 .col_droite {
	padding: 8vw 10vw 4vw 15vw;
	font-weight: 700;
}


// DIAPO 5
#entreprise_diapo_5 {
	height: $entreprise_image_bas_height;
	background: url(../images/image_illustration_5.jpg) no-repeat center center;
	background-size: cover;
}