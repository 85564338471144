#entete_container {
	position: relative;
	z-index: 10001;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	background-color: $color1;

	animation-name: reduction_entete_container;
	animation-delay: $decalage_phase2;
    animation-duration: $anim_duree_apparition_navbar;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#start_animation_container {
	position: relative;
	z-index: 10000;
	overflow: hidden;
	height: 100vh;
	width: 2000px;
	right: -120px;
	top: 50%;
	transform: translateY(-50%);

	animation-name: decalage_logo;
	animation-delay: $anim_duree_etape1 + $anim_duree_etape2 + $anim_duree_etape3;
    animation-duration: $anim_duree_etape4;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes decalage_logo {
    from {right: -120px;}
    to {right: 20px}
}

@media (min-width: 1800px) { 
	@keyframes decalage_logo { from {right: calc(-70px - 2vw); } to {right: calc(0px - 2vw)} }
	#start_animation_container { right: calc(-70px - 2vw); }
}

@media (max-width: 1500px) { 
	@keyframes decalage_logo { from {right: -35px;} to {right: 35px} }
	#start_animation_container { right: -35px; }
}

@media (max-width: 1400px) { 
	@keyframes decalage_logo { from {right: -20px;} to {right: 50px} }
	#start_animation_container { right: -20px; }
}

@media (max-width: 1300px) { 
	@keyframes decalage_logo { from {right: 0px;} to {right: 70px} }
	#start_animation_container { right: 0px; }
}

@media (max-width: 1200px) { 
	@keyframes decalage_logo { from {right: 20px;} to {right: 90px} }
	#start_animation_container { right: 20px; }
}

@media (max-width: 1100px) { 
	@keyframes decalage_logo { from {right: 30px;} to {right: 100px} }
	#start_animation_container { right: 30px; }
}

@media (max-width: 1000px) { 
	@keyframes decalage_logo { from {right: 45px;} to {right: 115px} }
	#start_animation_container { right: 45px; }
}

@media (max-width: 900px) { 
	@keyframes decalage_logo { from {right: 60px;} to {right: 130px} }
	#start_animation_container { right: 60px; }
}

@media (max-width: 800px) { 
	@keyframes decalage_logo { from {right: 80px;} to {right: 150px} }
	#start_animation_container { right: 80px; }
}

@media (max-width: 700px) { 
	@keyframes decalage_logo { from {right: 95px;} to {right: 165px} }
	#start_animation_container { right: 95px; }
}

@media (max-width: 600px) { 
	@keyframes decalage_logo { from {right: 110px;} to {right: 180px} }
	#start_animation_container { right: 110px; }
}

@media (max-width: $screen-phone) { 
	@keyframes decalage_logo { from {right: -30px;} to {right: 10px} }
	#start_animation_container { right: -30px; }
}

@media (max-width: 400px) { 
	@keyframes decalage_logo { from {right: -10px;} to {right: 25px} }
	#start_animation_container { right: -10px; }
}


@keyframes arrivee_zone_violette {
    from {left: 100vw;}
    to {left: 35vw;}
}

#zone_violette {
	position: absolute;
	z-index: 1;
	height: 1000px;
	width: 1600px;
	top: calc(-500px + 50vh);

	animation-name: arrivee_zone_violette;
    animation-duration: $anim_duree_etape1;
    -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
    animation-fill-mode: forwards;
}

@keyframes opacity_0_to_1 {
    from {opacity: 0;}
    to {opacity: 1;}
}

#rond_logo {
	position: absolute;
	z-index: 0;
	height: 1000px;
	width: 800px;
	top: calc(-500px + 50vh);
	left: calc(22vw*1.6);
	transform: translateX(-50px);
	opacity: 0;

	animation-name: opacity_0_to_1;
	animation-delay: $anim_duree_etape1;
    animation-duration: $anim_duree_etape1*0.2;
    -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
    animation-fill-mode: forwards;
}

#svg_zone_violette {
	height: 100%;
	width: 100%;
}

#svg_rond_logo {
	height: 100%;
	width: 100%;
}

#path_zone_violette {
	fill: $color2;
}

#path_rond_logo {
	fill: transparent;
	stroke: $color3;;
	stroke-width: $epaisseur_rond_logo;
}

#path_contour_blanc {
	fill: transparent;
	stroke: $color1;
	stroke-width: $ecart_violet_rond;
	stroke-linecap: round;
}

@keyframes black_to_white {
    from {fill: $color3;}
    to {fill: #ffffff;}
}

#path_petit_triangle {
	fill: $color3;

	animation-name: black_to_white;
	animation-delay: $anim_duree_etape1;
    animation-duration: $anim_duree_etape2;
    -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
    animation-fill-mode: forwards;
}

#logo_texte {
	position: absolute;
	z-index: 10;
	height: $logo_texte_height;
	width: $logo_texte_height*5;
	background: url(../images/texte_logo.png) no-repeat;
	background-size: contain;
	top: 50vh;
	transform: translateX(50px) translateY(-50%);
	left: calc(35vw + 80px);
	opacity: 0;

	animation-name: apparition_texte_logo;
	animation-delay: $anim_duree_etape1 + $anim_duree_etape2 + $anim_duree_etape3;
    animation-duration: $anim_duree_etape4;
    -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
    animation-fill-mode: forwards;
}

@keyframes apparition_texte_logo {
    from { left: calc(35vw + 1px); opacity: 0; }
    to { left: calc(35vw + 80px); opacity: 1; }
}

@media (max-width: $screen-phone) { 

	#logo_texte {
		display: none;
	}
}



// ----------------------------------------- PHASE 2 APRES APPARITION LOGO -----------------------------------------

@keyframes apparition_navbar {
    from { transform: translateY(-100%); }
    to { transform: translateY(0%); }
}

.navbar_accueil {
	transform: translateY(-100%);

	animation-name: apparition_navbar;
	animation-delay: $decalage_phase2;
    animation-duration: $anim_duree_apparition_navbar;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes reduction_entete_container {
    from { height: 100vh; }
    to { height: 85vh; }
}

/* @keyframes apparition_fullpage_accueil {
    from { height: 0px; }
    to { height: auto; }
} */

.fullpage_accueil {
	position: relative;
	overflow: hidden;
	/* height: 0px; */

	animation-name: apparition_fullpage_accueil;
	animation-delay: $decalage_phase2 - 0.2s;
    animation-duration: 0.1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes apparition_textes_startanimation {
    from { opacity: 0; transform: translateY(150%); }
    to { opacity: 1; transform: translateY(0%); }
}

.gros_mots_anim {
	position: absolute;
	font-size: calc(4em + 3vw);
	font-weight: 600;
}

#animaccueil_txt1 {
	position: absolute;
	font-size: 1.7em;
	font-weight: 600;
	top: 15%;
	left: 10%;
	opacity: 0;
	color: $color3;

	animation-name: apparition_textes_startanimation;
	animation-delay: $decalage_phase2 + 0.5s;
    animation-duration: $anim_duree_apparition_navbar;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

}

#animaccueil_txt2 {
	position: absolute;
	font-size: 1.4em;
	right: 10%;
	bottom: 10%;
	opacity: 0;
	color: $color3;

	animation-name: apparition_textes_startanimation;
	animation-delay: $decalage_phase2 + 0.5s;
    animation-duration: $anim_duree_apparition_navbar;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@media (max-width: $screen-md) { 
	#animaccueil_txt2 {
		padding-left: 10%;
	}
}

#animaccueil_mot1 {
	z-index: 1;
	top: 35%;
	left: 10%;
	transform: translateX(-50%);
	opacity: 0;

	animation-name: animaccueil_mot1;
	animation-delay: $decalage_phase2;
    animation-duration: $anim_duree_apparition_gros_textes;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes animaccueil_mot1 {
    from { opacity: 0; left: 10%; }
    to { opacity: 1; left: 40%; }
}

#animaccueil_mot2 {
	z-index: 1;
	top: 42%;
	right: -10%;
	transform: translateX(50%);
	opacity: 0;


	animation-name: animaccueil_mot2;
	animation-delay: $decalage_phase2;
    animation-duration: $anim_duree_apparition_gros_textes;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes animaccueil_mot2 {
    from { opacity: 0; right: -10%; }
    to { opacity: 1; right: 50%; }
}

#animaccueil_mot3 {
	z-index: 1;
	top: 50%;
	right: 0%;
	transform: translateX(50%);
	opacity: 0;

	animation-name: animaccueil_mot3;
	animation-delay: $decalage_phase2;
    animation-duration: $anim_duree_apparition_gros_textes;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes animaccueil_mot3 {
    from { opacity: 0; right: 0%; }
    to { opacity: 1; right: 35%; }
}