#nouscontacter_diapo_1 {
	position: relative;
	height: 50vh;
}

#nouscontacter_diapo_1 #main_logo {
	transform: translate(-50%, 50%) !important;
}

#grosmot_nouscontacter {
	top: 30%;
	left: 70%;
	transform: translateX(-50%);
	text-align: center;
	animation-name: grosmot_nouscontacter;
	animation-delay: 0s;
    animation-duration: $anim_grosmot_entetes_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes grosmot_nouscontacter {
    from { opacity: 0; left: 70%; }
    to { opacity: 1; left: 50%; }
}

#texte_debut_nouscontacter {
	position: absolute;
	text-align: center;
	bottom: 20px;
	width: 100vw - ($epaisseur_cadre * 2);
	text-align: center;
	padding: 0 50px 20px 20px !important;
}

#nouscontacter_diapo_2 {
	position: relative;
}

#nouscontacter_diapo_2 .text-center a {
	color: $color3;
	text-decoration: underline;
}

#nouscontacter_diapo_2 .text-center a:hover {
	text-decoration: underline;
}

#formulaire_container {
	left: 50%;
	transform: translateX(-50%);
}

.form-group input {
	border: none;
	background-color: $color1;
	border-bottom: 1px solid black;
	box-shadow: none;
	-webkit-box-shadow: none;
	font-style: italic;
}

.form-group textarea {
	border: none;
	background-color: $color1;
	border-top: 1px solid black;
	border-bottom: 1px solid black;
	box-shadow: none;
	-webkit-box-shadow: none;
	font-style: italic;
	margin-top: 50px;
}

.btn-info {
	color: $color3;
	background-color: $color1;
	border: none;
	font-size: 2em;
	text-decoration: underline;
}

.btn-info:hover, .btn-info:active, .btn-info:visited, .btn-info:focus {
	color: black !important;
	background-color: $color1 !important;
	text-decoration: underline !important;
}

.mail_result {
	padding: 20px;
	color: white;
	background-color: $color2;
}

#confirmation_envoi_mail_ok {
	display: none;
}

#confirmation_envoi_mail_fail {
	display: none;
}

#nouscontacter_image_bas {
	width: 100%;
	height: $images_illustration_bas_height;
	background: url(../images/illustration_nouscontacter.jpg) no-repeat center center;
	background-size: cover;
}