// Dans ce fichier, qui est appellé par la task gulp, on va simplement donner les chemins vers les différents fichiers scss

@import '_variables.scss';
@import '(bootstrap).scss';
@import 'margin_classes.scss';

@import 'global.scss';
@import 'animations_accueil.scss';
@import 'page_accueil.scss';
@import 'page_news.scss';
@import 'page_entreprise.scss';
@import 'page_activite.scss';
@import 'page_nousrejoindre.scss';
@import 'page_nouscontacter.scss';

@import 'tarteaucitron.scss';

@import '../css/font-awesome.min.css';
