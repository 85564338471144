#nousrejoindre_diapo_1 {
	position: relative;
	height: 80vh;
}

#grosmot_nousrejoindre {
	top: 20%;
	left: 70%;
	transform: translateX(-50%);
	text-align: center;
	animation-name: grosmot_nousrejoindre;
	animation-delay: 0s;
    animation-duration: $anim_grosmot_entetes_duree;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes grosmot_nousrejoindre {
    from { opacity: 0; left: 70%; }
    to { opacity: 1; left: 50%; }
}

#texte_debut_nousrejoindre {
	position: absolute;
	top: 50%;
	width: 100vw - ($epaisseur_cadre * 2);
	text-align: center;
	padding: 0 50px 20px 20px !important;
}

#paragraphe_nousrejoindre {
	font-weight: 500;
}

#texte_debut_nousrejoindre a {
	color: $color3;
	text-decoration: underline;
}

#texte_debut_nousrejoindre a:hover {
	color: $color3;
	text-decoration: underline;
}

#nousrejoindre_image_bas {
	width: 100%;
	height: $images_illustration_bas_height;
	background: url(../images/illustration_nousrejoindre.jpg) no-repeat center center;
	background-size: cover;
}