// ------------------------------
// ||   Variables générales    ||
// ------------------------------

$color1: #e6e6e6; // Gris clair
$color2: #8c189b; // Violet
$color3: #565c66; // Gris foncé
$color4: #8c189b; // Qui sommes nous : #cc3366
$color4b: #cc3366; // Qui sommes nous : #cc3366
$color5: #8c189b; // Ce que nous pouvons faire : #f6be44
$color5b: #f6be44; // Ce que nous pouvons faire : #f6be44
$color6: #f5b2c7; // Rose dragé
$epaisseur_cadre: 3vw;

// Nav
$titre_nav_color: #aaaaaa;
$color_nav_links: #bbbbbb;
$nav_barre_verticale_height: 20vh;
$nav_barre_verticale_margintop: 40vh;
$nav_contact_bloc_margintop: 10vh;

// Animations titres
$anim_titre_duree_anim: 1.5s;

// Général
$phrase_haut_page_margintop: 40px;
$main_logo_top: 40%;
$main_logo_size: 70px;
$main_logo_ecart_logotxt: 20px;
$anim_grosmot_entetes_duree: 2s;
$anim_main_logo_duree: 1s;

// Rejoindre et contacts
$images_illustration_bas_height: 60vh;


// ------------------------------
// ||   Variables Bootstrap    ||
// ------------------------------

$navbar-height: 40px;
$grid-float-breakpoint: 999999999px;
$input-border: none !important;
$input-border-radius: 0px !important;
$input-border-focus: $color1;



// ----------------------------------------
// ||     Page : Animation Accueil       ||
// ----------------------------------------

$ecart_violet_rond: 1px;
$epaisseur_rond_logo: 2px;

$anim_duree_etape1: 1s;
$anim_duree_etape2: 1s;
$anim_duree_etape3: 1s;
$anim_duree_etape4: 0.5s;
$decalage_phase2: $anim_duree_etape1 + $anim_duree_etape2 + $anim_duree_etape3 +  $anim_duree_etape4;
$anim_duree_apparition_navbar: 1s;
$anim_duree_apparition_gros_textes: 3s;




// ----------------------------------------
// ||           Page : Accueil           ||
// ----------------------------------------

$logo_texte_height: 70px;

$dessin1_delai_animation: 1.5s;
$dessin2_delai_animation: 1.5s;

$puce_liste_size: 20px;



// ---------------------------------
// ||      Page : Actualités      ||
// ---------------------------------

$news_texte_fontsize: 1.2em;
$news_margin_titre_img: 20px;
$news_margin_bas_texte: 0px;
$news_margin_avant_share: 15px;
$news_img_height: 350px;
$news_decalage_anchor: 100px;




// ----------------------------------------
// ||      Page : Qui sommes nous        ||
// ----------------------------------------

$hauteur_au_dessus_texte: 40vw;
$entreprise_diapo3_bghaut_height: 50vw;
$entreprise_diapo3_bgbas_height: 50vw;
$animation_cible_container_height: 100vw;
$groupe_fleches_height: 35vw;
$groupe_points_height: 35vw;
$fleches_etiquettes_size: 40px;
$entreprise_image_bas_height: 50vw;



// ---------------------------------------
// ||          Page : Activité          ||
// ---------------------------------------

// Animations ronds
$delay_animation_jauge: 0.6s;
$duree_animation_jauge: 1s;
$activite_arrivee_img_delay: 0s;
$activite_arrivee_img_duree: 0.6s;

$symetrie: 1;
$rayon: 38%; // Rayon, le même que dans l'html du svg
$decalage: -45; // Décalage en degrés
$circonference: $rayon * 2 * 3.14159; // Rayon * 2 * Pi
$taille_jauge: 0.75;
$niveau_jauge_1: 0.25;
$niveau_jauge_2: 0.50;
$niveau_jauge_3: 0.75;
$niveau_jauge_4: 1;
$niveau_jauge_5: 1;

// Bas
$activite_diapo6_height: 30vh;


// ---------------------------------
// ||           Footer            ||
// ---------------------------------

$footer_logo_height: 60px;



// COOKIES : Tarteaucitron
$cookie_banner_background: #333;
$cookie_allowbtn_background: #008300;
$cookie_denybtn_background: #9c1a1a;
$cookie_personnaliserbtn_background: #fff;
$cookie_reopen_btn_background: #333;